import {Injectable} from '@angular/core';


@Injectable()
export class FormatIntranetShareService {

  public urlFicherContact(id) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=vuedetail&pers_ID=' + id;
  }

  public urlSuiviRelance(contactId, socId1) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=nouveau&IDcontactpar=' + contactId + '&IDScieClient1=' + socId1 + '&IDScieClient2=0';
  }

  public urlSuiviRelanceBis(contactId, socId1, socId2) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=nouveau&IDcontactpar=' + contactId + '&IDScieClient1=' + socId1 + '&IDScieClient2=' + socId2;
  }

  public urlSuiviRelanceExiste(rappelId) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=vuedetail&rappel_ID=' + rappelId;
  }

  public urlSuiviSupport(supportId) {
    return 'http://intranet.escalconsulting.com/supports_cms.php?action=vuedetail&support_ID=' + supportId;
  }

  public urlAjoutSupport(contactId) {
    return 'http://intranet.escalconsulting.com/supports_asso.php?action=vuedetail&ID_contact=' + contactId;
  }

  public urlSuiviContact(contactId) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=vuedetail&pers_ID=' + contactId;
  }

  public urlSuiviCalRed(calRedId) {
    return 'http://intranet.escalconsulting.com/calendrier_cms.php?action=vuedetail&calendrier_ID=' + calRedId;
  }

  public urlSuivi(suiviId) {
    return 'http://intranet.escalconsulting.com/contacts_suivi_cms.php?action=vuedetail&ID_suivi=' + suiviId;
  }
}

