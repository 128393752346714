import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';
import {Client} from '../../../model/client/Client';


@Injectable()
export class ClientService {
    private clientUrl = Environement.BASE_API_URL + '/api/clients';
    private motsClesUrl = Environement.BASE_API_URL + '/api/mots_cles';
    constructor(private http: HttpClient) { }

  getClientList(actuel) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '?pagination=false&actuel=' + actuel + '&order[nom]', {headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }
    getClient(page, actuel): Promise<any> {
      const headers = this.initOptionHeader();
      return this.http.get(this.clientUrl + '?_page=' + page + '&actuel=' + actuel + '&order[id]=desc', {headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }
    getClientDetail(id): Promise<any> {
      const headers = this.initOptionHeader();
      return this.http.get(this.clientUrl + '/' + id , {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

    changerStatut(client: Client) {
      const headers = this.initOptionHeader();
      return this.http.put(this.clientUrl + '/' +  client.id, { 'actuel': client.actuel},
        {headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

  modifierClientOVH(client: Client, adresseGenerique, adresseCoupure) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/' +  client.id + '/modifier/' + adresseGenerique + '/' + adresseCoupure,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

    modifierInfos(client: Client) {
      const headers = this.initOptionHeader();
      return this.http.put(this.clientUrl + '/' +  client.id, { 'nom': client.nom, 'dirigeant': client.dirigeant, 'ancienId': client.ancienId,
          adresseGenerique: client.adresseGenerique, adresseCoupure: client.adresseCoupure},
        {headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

    getClientsRecherche(recherche: Array<any>, page, actuel) {
        const content = recherche[0];
        let type = recherche[1];
        const headers = this.initOptionHeader();
        type = removeAccents(type.toLowerCase());
        if (type === 'tous') {
            return this.http.get(this.clientUrl + '?several_all=' + content + '&_page=' + page + '&actuel=' + actuel + '&order[nom]',
                {headers} ).toPromise()
                .then(response => response)
                .catch(this.errorHandler);
        }
        return this.http.get(this.clientUrl + '?' + type + '=' + content + '&_page=' + page + '&actuel=' + actuel + '&order[nom]',
            {headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }

  getClientsRechercheMotsCles(recherche: Array<any>) {
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    return this.http.get(this.motsClesUrl + '?several_all=' + content ,
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }

  rechercheClientsScore(score, actuel) {
    score = score.toUpperCase();
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/scoreClients/score/' + score + '/' + actuel ,
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getClientsDates(dateDebut, dateFin, actuel) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/rechercheDates/' + dateDebut + '/' + dateFin + '/' + actuel , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getClientsDatesTri(dateDebut, dateFin, actuel, typeTri) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/rechercheDatesTri/' + dateDebut + '/' + dateFin + '/' + actuel + '/' + typeTri , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addClient(client: Client) {
    const headers = this.initOptionHeader();
    return this.http.post(this.clientUrl , {nom: client.nom, adresseGenerique: client.adresseGenerique,
      adresseCoupure: client.adresseCoupure, actuel: true, ancienId: client.ancienId, dirigeant: client.dirigeant },
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateMotsCles(client: Client, motCleID, action) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/' +  client.id + '/' + motCleID + '/' +  action, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateClientMotsCles(client: Client) {
    let dateEntree;
    let dateSortie;
    let dateDebutOk;
    let dateFinOk;
    dateDebutOk = new Date(client.dateEntree.getTime() + (2 * 1000 * 60 * 60));
    dateFinOk = new Date(client.dateSortie.getTime() + (2 * 1000 * 60 * 60));
    dateEntree = dateDebutOk.getFullYear() + '-' + (dateDebutOk.getMonth() + 1).toString() + '-' + dateDebutOk.getDate() + 'T00:00:00';
    dateSortie = dateFinOk.getFullYear() + '-' + (dateFinOk.getMonth() + 1).toString() + '-' + dateFinOk.getDate() + 'T00:00:00';
    const headers = this.initOptionHeader();
    return this.http.put(this.clientUrl + '/' + client.id , {score: client.score,
        duree: client.duree, dateSortie: dateSortie, dateEntree: dateEntree}
        , { headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  rechercheClients(motsClesList, actuel) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/motsClesList/recherche/' +  motsClesList + '/' + actuel , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    }
    private initOptionHeader() {
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
