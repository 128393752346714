import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {NgxSpinnerService} from 'ngx-spinner';
import {Environement} from '../../../../environement/environement';


@Component({
  selector: 'app-sommaire-liste-parution',
  templateUrl: './sommaire-liste-parution.component.html',
  styleUrls: ['./sommaire-liste-parution.component.scss'],
  providers: [ ParutionService ]
})
export class SommaireListeParutionComponent implements OnInit {



  id: number;
  sub: any;
  parution: any;
  optionPolice: Array<number>;
  policeChose: number;
  inclureNomParution: boolean;
  trierClient: boolean;
  trierTypeParution: boolean;
  isClient = true;
  orderDateCroissant = true;
  repriseCp = false;
  erreurs;
  couleur = false;
  couleurAgence = '#000000';
  couleurAudiovisuel = '#000000';
  couleurPrint = '#000000';
  couleurOnline = '#000000';
  linkSommaire = false;
  linkPressBook = false;

  listeTypeParution = [
    {
      content: 'Agence de presse',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Audiovisuel',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Print',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Online',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
  ];


  constructor(private  route: ActivatedRoute, private parutionService: ParutionService,
              private dialogueShareService: DialogueShareService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.erreurs = [];
    this.optionPolice = [];
    this.optionPolice.push(8);
    this.optionPolice.push(9);
    this.optionPolice.push(10);
    this.optionPolice.push(11);
    this.optionPolice.push(12);
    this.optionPolice.push(13);
    this.optionPolice.push(14);
    this.optionPolice.push(18);
    this.optionPolice.push(24);
    this.optionPolice.push(32);
    this.policeChose = 10;
    this.trierClient = false;
    this.inclureNomParution = false;
    this.trierTypeParution = false;
    this.sub = this.route.params.subscribe(params => {
      this.id = +params.id;

      this.parutionService.getInfoSommaire(this.id)
        .then((retour) => {
          this.parution = retour;
          this.initIsClient();
        });
      this.initLink();
    });
  }

  initLink(){
    this.parutionService.getPressBookGenerer(this.id)
      .then((retour) => {
        if (retour['generer'] === 'false') {
          this.linkSommaire = false;
          this.linkPressBook = false;
        } else {
          this.linkSommaire = true;
          this.linkPressBook = true;
        }
      });
  }

  genererSommaire() {
    this.erreurs = [];
    this.spinner.show();
    this.parutionService.genererSommaire(this.id, this.policeChose, this.trierClient, this.trierTypeParution,
      this.inclureNomParution, this.listeTypeParution, this.repriseCp, this.orderDateCroissant, this.couleur, this.couleurPrint,
      this.couleurAudiovisuel, this.couleurOnline, this.couleurAgence)
        .then( (retour) => {
          this.spinner.hide();
          if (typeof (retour['type']) !== 'undefined'){
            this.initLink();
            this.erreurs = retour['erreurs'];
          } else {
            this.parutionService.getPressBookGenerer(this.id)
              .then((retour1) => {
                console.log(retour1);
                window.location.reload();
              });
          }

        });
  }
  initIsClient() {
    if (!this.parution['client']) {
      this.isClient =  false;
      return;
    }
    if (isNaN(this.parution['client'])) {
      this.isClient = false;
      return;
    }
    if (this.parution['client'] < 1) {
      this.isClient = false;
      return;
    }
    if (this.parution['client'] === '3445') {
      this.isClient = false;
      return;
    }
    this.isClient = true;
  }

  genererLinkPressBook(){
    const rand1 = Math.floor(Math.random() * Math.floor(1000000));
    const rand2 = Math.floor(Math.random() * Math.floor(1000000));
    return Environement.BASE_API_URL + '/sommaire/' + this.id + 'pressbook.pdf?' + rand1 + '=' + rand2;
  }
  genererLinkSommaire(){
    const rand1 = Math.floor(Math.random() * Math.floor(1000000));
    const rand2 = Math.floor(Math.random() * Math.floor(1000000));
    return Environement.BASE_API_URL + '/sommaire/' + this.id + 'sommaire.docx?' + rand1 + '=' + rand2;
  }
}
