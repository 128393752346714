import { Component, OnInit } from '@angular/core';
import {Client} from '../../../../model/client/Client';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-client-modifier',
  templateUrl: './escal-client-modifier.component.html',
  styleUrls: ['./escal-client-modifier.component.scss'],
  providers: [ ClientService ]
})
export class EscalClientModifierComponent implements OnInit {

  private sub: any;
  client: Client;
  adresse: boolean;
  ancienneAddrGene: string;
  ancienneAddrCoup: string;
  constructor(private route: ActivatedRoute, private clientService: ClientService, private dialogueShareService: DialogueShareService,
              private router: Router) { }

  ngOnInit() {
    this.adresse = true;
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      this.clientService.getClientDetail(params.id)
        .then((retour) => {
          that.client = retour as Client;
          this.ancienneAddrGene = that.client.adresseGenerique;
          this.ancienneAddrCoup = that.client.adresseCoupure;
        });
    });
  }

  modifierClient() {
    if (this.client.nom.length < 1) {
      this.dialogueShareService.addDialogue('Le nom du client est obligatoire');
    } else if (this.client.ancienId === null) {
      this.dialogueShareService.addDialogue('L\' ancien id est obligatoire');
    } else {
      if (this.adresse === true) {
        this.client.adresseGenerique = this.client.nom.toLowerCase().replace(/\s/g, '') + '@escalconsulting.com';
        this.client.adresseCoupure = 'coupures-' + this.client.nom.toLowerCase().replace(/\s/g, '') + '@escalconsulting.com';
      }
      this.clientService.modifierInfos(this.client)
        .then(() => {
          if (this.adresse === true) {
            this.clientService.modifierClientOVH(this.client, this.ancienneAddrGene, this.ancienneAddrCoup)
              .then(() => {
                this.dialogueShareService.addDialogue('Informations mises à jour');
                this.router.navigate(['/escal/client/', this.client.id]);
              });
          } else {
            this.dialogueShareService.addDialogue('Informations mises à jour');
            this.router.navigate(['/escal/client/', this.client.id]);
          }
        });
    }
  }

isActive(event) {
    if (event == true) {
      this.adresse = true;
    } else {
      this.adresse = false;
    }
}

}
