import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ClientService} from '../../../../service/interne/client/ClientService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {LogoService} from '../../../../service/interne/utilitaire/LogoService';

import {Client} from '../../../../model/client/Client';


@Component({
  selector: 'app-escal-client-add',
  templateUrl: './escal-client-add.component.html',
  styleUrls: ['./escal-client-add.component.scss'],
  providers: [ClientService, LogoService]
})
export class EscalClientAddComponent implements OnInit {


  client: Client;
  generique: boolean;
  coupure: boolean;
  logoUpload: Array<any> = [];

  constructor(private dialogueShareService: DialogueShareService, private clientService: ClientService, private logoService: LogoService,
              private router: Router) {
  }

  ngOnInit() {
    this.client = new Client(0, '', true, '', null, [], '', '',
      null, [], [], null, null, null, '', []);
  }

  adressGeneriqueChange() {
    if (this.generique === false) {
      this.client.adresseGenerique = '';
    } else {
      if (this.client.nom.length > 2) {
        this.client.adresseGenerique = this.client.nom.toLowerCase().replace(/\s/g, '') + '@escalconsulting.com';
      } else {
        this.generique = false;
        this.dialogueShareService.addDialogue('merci de d\'entrer le nom du client');
      }
    }
  }

  adressCoupureChange() {
    if (this.coupure === false) {
      this.client.adresseGenerique = '';
    } else {
      if (this.client.nom.length > 2) {
        this.client.adresseCoupure = 'coupures-' + this.client.nom.toLowerCase().replace(/\s/g, '') + '@escalconsulting.com';
      } else {
        this.coupure = false;
        this.dialogueShareService.addDialogue('Merci de d\'entrer le nom du client');
      }
    }
  }

  saveCLient() {
    const that = this;
    var nb = 0;
    var compteur = 0;
    if (this.client.ancienId === null) {
      this.dialogueShareService.addDialogue('L\'ancien id est obligatoire');
    } else {
      this.clientService.getClientList(1).then((clients) => {
        clients['hydra:member'].forEach(client => {
          nb = nb + 1;
          console.log(this.client.ancienId);
          if (client.nom == this.client.nom) {
            this.dialogueShareService.addDialogue('Le nom saisi existe déjà');
          }  else if (nb === clients['hydra:member'].length) {
            if (that.logoUpload['filename'] === undefined) {
              this.clientService.addClient(this.client)
                .then((retour) => {
                  this.router.navigate(['/escal/client/actuel']);
                  this.dialogueShareService.addDialogue('Le client est bien créé');
                });
            } else {
              that.logoService.getLogo().then((logos) => {
                logos['hydra:member'].forEach(logo => {
                  compteur = compteur + 1;
                  if (logo.url == that.logoUpload['filename']) {
                    this.dialogueShareService.addDialogue('Un fichier porte déjà ce nom de logo');
                  } else if (compteur === logos['hydra:member'].length) {
                    this.clientService.addClient(this.client)
                      .then((retour) => {
                        that.client = retour as Client;
                        that.logoService.addLogo(that.client.id, that.logoUpload)
                          .then((retour1) => {
                            this.router.navigate(['/escal/client/actuel']);
                            this.dialogueShareService.addDialogue('Le client est bien créé');
                          });
                      });
                  }
                });
              });
            }
          }
        });
      });
    }
  }

  changeLogo(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.logoUpload['filename'] = file.name;
        that.logoUpload['filetype'] = file.type;
        if (typeof reader.result === 'string') {
          that.logoUpload['value'] = reader.result.split(',')[1];
        }
      };
    }
  }
}
