import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {Environement} from '../../../../../environement/environement';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-client-fiche-informations',
  templateUrl: './escal-client-fiche-informations.component.html',
  styleUrls: ['./escal-client-fiche-informations.component.scss']
})
export class EscalClientFicheInformationsComponent implements OnInit {

  @Input()
  client: Client;
  premierMoitieDirigeant: string;
  secondMoitieDirigeant: string;
  premierMoitieClient: string;
  secondMoitieClient: string;
  premierMoitieGene: string;
  secondMoitieGene: string;
  premierMoitieCoup: string;
  secondMoitieCoup: string;
  @Output()
  baseServeur =  Environement.BASE_API_URL;
  constructor(private router: Router) { }

  ngOnInit() {
    // si chaine de caractère trop longue faire un retour à la ligne
    if (this.client.dirigeant) {
      if (this.client.dirigeant.length > 25) {
        this.premierMoitieDirigeant = this.client.dirigeant.substring(0, 25);
        this.secondMoitieDirigeant = this.client.dirigeant.substring(25);
      }
    }
    if (this.client.nom.length > 25) {
      this.premierMoitieClient = this.client.nom.substring(0, 25);
      this.secondMoitieClient = this.client.nom.substring(25);
    }
    if (this.client.adresseGenerique.length > 45) {
      this.premierMoitieGene = this.client.adresseGenerique.substring(0, 45);
      this.secondMoitieGene = this.client.adresseGenerique.substring(45);
    }
    if (this.client.adresseCoupure.length > 45) {
      this.premierMoitieCoup = this.client.adresseCoupure.substring(0, 45);
      this.secondMoitieCoup = this.client.adresseCoupure.substring(45);
    }
  }

}
