import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class ParutionService {
    private parutionUrl = Environement.BASE_API_URL + '/parutions';
    constructor(private http: HttpClient) { }

  getInfoSommaire(id){
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/infoSommaire/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  genererSommaire(id, policeChose, trierClient, trierTypeParution, inclureTitreParution,
                  listeTypeParution,  repriseCp, orderDateCroissant, couleur, couleurPrint, couleurAudiovisuel, couleurOnline,
                  couleurAgence): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.parutionUrl + '/genererSommaire', {'id': id, 'police': policeChose, 'trierClient': trierClient,
        'trierTypeParution': trierTypeParution, 'inclureTitreParution': inclureTitreParution, 'listeTypeParution': listeTypeParution,
      'repriseCp': repriseCp, 'orderDateRecentLast': orderDateCroissant, 'couleur': couleur, 'couleurPrint': couleurPrint,
        'couleurAudiovisuel': couleurAudiovisuel, 'couleurOnline': couleurOnline, 'couleurAgence': couleurAgence },
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getPressBookGenerer(id){
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/getLink/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }




  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
    }
}
