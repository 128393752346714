import {Component, EventEmitter, Input, OnInit, Output, SimpleChange} from '@angular/core';

@Component({
  selector: 'app-pagination-paginateur',
  templateUrl: './pagination-paginateur.component.html',
  styleUrls: ['./pagination-paginateur.component.scss']
})
export class PaginationPaginateurComponent implements OnInit {


  @Input()
  page: number;
  @Input()
  maxPage: number;
  @Output() changePage = new EventEmitter<number>();
  pages: Array<any> = [];

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {

    if (changes['maxPage']) {
      this.initPage();
    }
    if (changes['page']){
      this.initPage();
    }
  }

  constructor() { }

  ngOnInit() {
    this.initPage();
  }

  pageActif(page: number) {
    if(this.page === page){
      return 'active';
    }
    return 'waves-effect';
  }
  pagePos(nombre)
  {
    return this.page + nombre;
  }

  changerPage(value) {
    this.page = value;
    this.initPage();
    this.emitEvent();
  }
  initPage(){
    this.pages = [];
    if (this.page < 4) {
      this.pages.push({'value': 1, 'actif' : this.pageActif(1)});
      if (this.maxPagePossible(2)){
        this.pages.push({'value': 2, 'actif' : this.pageActif(2)});
      }
      if (this.maxPagePossible(3)){
        this.pages.push({'value': 3, 'actif' : this.pageActif(3)});
      }
      if (this.maxPagePossible(4)){
        this.pages.push({'value': 4, 'actif' : 'waves-effect'});
      }
      if(this.maxPagePossible(5)){
        this.pages.push({'value': 5, 'actif' : 'waves-effect'});
      }

    } else {
      this.pages.push({'value': this.pagePos(-2), 'actif' : 'waves-effect'});
      this.pages.push({'value': this.pagePos(-1), 'actif' : 'waves-effect'});
      this.pages.push({'value': this.pagePos(0), 'actif' : 'active'});
      if (this.maxPagePossible(this.pagePos(1))){
        this.pages.push({'value': this.pagePos(1), 'actif' : 'waves-effect'});
      }
      if (this.maxPagePossible(this.pagePos(2))){
        this.pages.push({'value': this.pagePos(2), 'actif' : 'waves-effect'});
      }
    }
  }
  pageMoins(){
    if (this.page > 0) {
      this.page = this.page - 1;
      this.emitEvent();
    }

  }
  pagePlus() {
    if (this.page < this.maxPage) {
      this.page = this.page + 1;
      this.emitEvent();
    }
  }
  emitEvent(){
    this.changePage.emit(this.page);
  }
  maxPagePossible(nombre: number) {
    return (nombre <= this.maxPage);
  }
}
