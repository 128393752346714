import { Component, OnInit } from '@angular/core';
import {Client} from '../../../../model/client/Client';
import {DocumentsService} from '../../../../service/interne/client/Document/DocumentsService';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {ActivatedRoute, Router} from '@angular/router';
import {Environement} from '../../../../environement/environement';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-client-document',
  templateUrl: './escal-client-document.component.html',
  styleUrls: ['./escal-client-document.component.scss'],
  providers: [DocumentsService, ClientService]
})
export class EscalClientDocumentComponent implements OnInit {
  private sub: any;
  active = 'pressbook';
  client: Client;
  fichierTelecharge: Array<any> = [];
  fichier = '';
  motsCles = '';
  toggleActive = false;
  typePressbook;
  typeBusiness;
  typeAutre;

  constructor(private document: DocumentsService, private route: ActivatedRoute, private clientService: ClientService,
              private dialogueShareService: DialogueShareService) {
  }

  ngOnInit() {
    const that = this;
    this.typePressbook = false;
    this.typeBusiness = false;
    this.typeAutre = false;
    this.motsCles = '';
    this.sub = this.route.params.subscribe(params => {
      this.clientService.getClientDetail(params['id'])
        .then((retour) => {
          that.client = retour as Client;
          that.client.motsCles.forEach(motcle => {
            that.motsCles += '#' + motcle.libelle + ' ' ;
          });
          that.client.documents.forEach(document => {
            if (document.type === 'pressbook') {
              that.typePressbook = true;
            } else if (document.type === 'business') {
              that.typeBusiness = true;
            } else if (document.type === 'autres') {
              that.typeAutre = true;
            }
          });
        });
    });
  }

  actived(type) {
    if (type === this.active) {
      this.toggleActive = false;
      return 'active';
    }
  }

  saveDocument() {
    const that = this;
    let existe = false;
    // Quand il n'y a pas de fichier
    if (this.fichierTelecharge['nom'] == undefined) {
      this.dialogueShareService.addDialogue('Veuillez ajouter un fichier');
    } else {
      // pré-enregistrement du lien
      this.fichierTelecharge['url'] = Environement.DOCUMENTS_URL +
        '/' + this.fichierTelecharge['type'] + '/' + this.fichierTelecharge['nom'];
      // Vérifier si le nom du fichier existe déjà  et que le type soit différent
      if (this.client.documents.length > 0) {
        this.client.documents.forEach(doc => {
          if (doc.nom == this.fichierTelecharge['nom']) {
            if (doc.type == this.fichierTelecharge['type']) {
              this.dialogueShareService.addDialogue('Le nom du fichier existe déjà');
              existe = true;
            }
          }
        });
        // Vérifier la longueur du nom du fichier
        if (this.fichierTelecharge['nom'].length > 70) {
          this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
          existe = true;
        }
        // Ajouter le fichier seulement si le nom n'existe pas et que la longueur est respecté
        if (existe === false) {
          this.document.ajouterDocument(this.client.id, this.fichierTelecharge)
            .then(() => {
              that.ngOnInit();
            });
          this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
        }
      } else {
        if (this.fichierTelecharge['nom'].length > 70) {
          this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
        } else {
          this.document.ajouterDocument(this.client.id, this.fichierTelecharge)
            .then(() => {
              that.ngOnInit();
            });
          this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
        }
      }
    }
  }

  deleteDocument(IDDocument) {
    const that = this;
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer ce fichier', function () {
      that.document.supprimerDocument(IDDocument)
        .then(() => {
          that.dialogueShareService.addDialogue('Le fichier est bien supprimé');
          that.ngOnInit();
        })
        .catch(() => {
          that.dialogueShareService.addDialogue('Erreur de supression');
        });
    });
  }

  typeDuDocument(document) {
    switch (document) {
      case 'pressbook':
        this.fichierTelecharge['type'] = 'pressbook';
        break;
      case 'business':
        this.fichierTelecharge['type'] = 'business';
        break;
      case 'autres':
        this.fichierTelecharge['type'] = 'autres';
        break;
    }
  }

  sansAccent(chaine) {
    var accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
    for (var i = 0; i < accent.length; i++) {
      chaine = chaine.replace(accent[i], noaccent[i]);
    }
    return chaine;

  }
  changeDocument(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fichier = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.fichierTelecharge['nom'] = this.sansAccent(that.client.nom + '_' + file.name).replace(' ', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].replace('’', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].split(' ').join('');
        // Eviter les chevauchements dess nom, url et date
        if (that.fichierTelecharge['nom'].length > 35 && that.fichierTelecharge['nom'].includes('-') === false) {
          let secondMoitieChaine;
          secondMoitieChaine = that.fichierTelecharge['nom'].substring(34);
          // Saut de ligne seulement si le nom comporte plus de 43 caractère
          if (secondMoitieChaine.length > 8) {
            let premierMoitieChaine;
            premierMoitieChaine = that.fichierTelecharge['nom'].substring(0, 34);
            that.fichierTelecharge['nom'] = premierMoitieChaine + '-' + secondMoitieChaine;
          }
        }
        that.fichierTelecharge['format'] = file.type;
        this.typeDuDocument(that.active);
        if (typeof reader.result === "string") {
          that.fichierTelecharge['value'] = reader.result.split(',')[1];
        }
      };
    }
  }
  modifierActive() {
    if (this.active === 'tous') {
      this.active = 'rien';
      this.toggleActive = false;
    } else if (this.active === 'rien') {
      this.active = 'tous';
      this.toggleActive = true;
    } else {
      this.active = 'tous';
      this.toggleActive = true;
    }
  }
}
